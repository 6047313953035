import React, { useState, useRef, useMemo, ReactNode } from "react";
import { useSidebarContext } from "../../context/SidebarContext";
import { FaBell } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import NotificationDropdown from "./NotificationDropdown";
import useIsUserAuthenticated from "../../hooks/useIsUserAuthenticated";
import useIsAdminAuthenticated from "../../hooks/useIsAdminAuthenticated";
import { Badge, Fab, Popover, Box, CssBaseline } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
/**
 * Component of the top bar to be displayed at all times.
 *
 * @returns {Component}
 */
const TopBar = () => {
  const { showSidebar, setShowSidebar } = useSidebarContext();
  const notifications = useAppSelector(
    (state) => state.notification.notificationList
  );
  const numUnreadNotifications = useMemo(() => {
    return notifications.filter((notif) => !notif.read).length;
  }, [notifications]);
  const isAuthenticated = useIsUserAuthenticated();
  const isAdminAuthenticated = useIsAdminAuthenticated();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const toggleSideBar = (): void => {
    setShowSidebar(!showSidebar);
  };

  //TODO: Figure out type w/ this event/function...
  const handleNotificationDropdownClick = (
    event: React.MouseEvent<HTMLElement>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationDropdownClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-1" : undefined;

  return (
    <>
      {showSidebar && (
        <div
          className="anywhere-else"
          onClick={() => setShowSidebar(false)}
        ></div>
      )}

      <CssBaseline />
      <div className="topbar-container">
        <div className="leftside">
          {(isAuthenticated() || isAdminAuthenticated()) && (
            <Fab
              onClick={toggleSideBar}
              className={"rounded " + (showSidebar && "active")}
              sx={{
                backgroundColor: "rgb(31, 140, 165)",
              }}
            >
              <FaBars fontSize={"1.2rem"} className="menu-icon" />
            </Fab>
          )}
          <img
            className="netnumber-logo"
            src="/NetNumber_FullLogo_RGB-1536x426-1.png"
            alt="netnumber"
          />
        </div>

        <div className="middle"></div>
        {(isAuthenticated() || isAdminAuthenticated()) && (
          <div className="rightside">
            <Fab
              onClick={handleNotificationDropdownClick}
              sx={{
                backgroundColor: "rgb(31, 140, 165)",
              }}
              id={id}
            >
              <Badge badgeContent={numUnreadNotifications} color="error">
                <FaBell fontSize={"1.2rem"} />
              </Badge>
            </Fab>
            <Popover
              sx={{
                padding: 4,
              }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleNotificationDropdownClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box
                sx={{
                  width: "30vw",
                  minWidth: "500px",
                  overflowY: "visible",
                }}
              >
                <NotificationDropdown
                  handleFilterDropdownClose={handleNotificationDropdownClose}
                />
              </Box>
            </Popover>
          </div>
        )}
      </div>
    </>
  );
};

export default TopBar;
